<template>
    <div class="header_dash_container">
        <div class="header_dash_user">
            <p>Bienvenido</p>
            <img src="../../../assets/img/thumbnail/logoCorporativo.png" width="40px" @click="openLogOut()" alt="Icono de usuario" srcset="" id="userIcon">
        </div>
        <div class="log_out_container" v-show="openLog" @click="doLogOut()">
            Cerrar sesión
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 150">
            <path fill="#ffffff" fill-opacity="1" d="M0,96L1440,96L1440,320L0,320Z"></path>
        </svg>
    </div>
</template>

<script>



export default {
    name:"Dheader",
    components:{
    },

    
    data(){
        return {
            openLog: false,
        }
    },

    methods:{
        openLogOut(){
            this.openLog = !this.openLog;
        },

        redirect(){
            this.$router.push({ name: "Register" });
        },

        async doLogOut(){
            try{    
                await this.$store.dispatch("user/doLogOut");
                this.$toast.success("Sesión cerrada con exito");
                this.redirect();
           } catch(e){
                this.$toast.error("Ocurrio un error, intente de nuevo");
            }
            
        },

    },

    computed:{
    },

   

}
</script>